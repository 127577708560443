// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.$ = window.jQuery = require("jquery")

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Bootstrap
require('bootstrap')
require('bootstrap-switch')
require('bootstrap-datepicker')
require('bootstrap-colorpicker')
require('bootstrap-select')

// Jasny bootstrap for file upload
require('jasny-bootstrap')

// Various
require("jquery-slimscroll")
require('object-fit-images')

// Menu
require('metismenu')

// Graphs
require('chart.js')
require('chartjs-plugin-annotation')

// Sparklines
require('jquery-sparkline')

// Google maps
require('gmaps.core')
require('gmaps.markers')

// Counters
require('jquery-countto')
require('jquery-countdown')

// Datatables
require('datatables.net')
require('datatables.net-buttons')
require('datatables.net-responsive')
require('datatables.net-rowgroup')
require('datatables-print.min')
require('ouanalyse-datatables.net-buttons-html5')
require('pdfmake')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Initialisation functions
document.addEventListener("turbolinks:load", function () {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="multiselect-dropdown"]').selectpicker();
  $('[data-toggle="date"]').datepicker({
    format: "dd-mm-yyyy"
  });
  $('.counter').countTo();
  $('#metismenu').metisMenu();
  $('.bootstrap-switch').bootstrapSwitch();

  $('.SideNavToggle').on('click', function () {
    $('body').toggleClass(
      'show-sidebar'
    )
  })
})

pdfMake.fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  }
}